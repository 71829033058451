import { DataService } from '../data/data.service';
import { FirebaseCollections } from '@app/enums/firebase-collections.enum';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { Purchase, PurchaseDTO } from '@app/models/purchase';
import { UsersService } from '../users/users.service';

@Injectable({
  providedIn: 'root'
})
export class PurchasesService extends DataService {
  private readonly usersService: UsersService = inject(UsersService);

  public getPurchases(): Observable<Purchase[]> {
    return this.getDocuments(
      `${FirebaseCollections.USERS}/${this.usersService.entity.value?.uid}/${FirebaseCollections.PURCHASES}`,
      null,
      [
        {
          type: 'orderBy',
          fieldPath: 'created',
          directionStr: 'desc'
        }
      ]
    );
  }

  public getPurchase(id: string): Observable<Purchase> {
    return this.getDocument(
      `${FirebaseCollections.USERS}/${this.usersService.entity.value?.uid}/${FirebaseCollections.PURCHASES}/${id}`
    ) as Observable<Purchase>;
  }

  public async addPurchase(data: PurchaseDTO): Promise<Purchase> {
    return this.addDocument(
      `${FirebaseCollections.USERS}/${this.usersService.entity.value?.uid}/${FirebaseCollections.PURCHASES}`,
      FirebaseCollections.PURCHASES,
      data
    );
  }
}
